<template>
  <div class="home">
    <div :class="`home-loader ${isLoading ? 'home-loader--active' : ''}`">
      <img class="logo" src="@/assets/img/Logo_bl.svg" />
    </div>

    <div :class="`sidebar ${sideBarClass}`">
      <button
        class="sidebar-arrow sidebar-arrow--top"
        :disabled="activeSection <= 1"
        @click="setActiveSection(activeSection - 1)"
      ></button>
      <div class="sidebar-sections">
        <button
          :key="section"
          v-for="section in 8"
          @click="setActiveSection(section)"
          class="sidebar-sections__item"
          :class="activeSection === section ? 'sidebar-sections__item--active' : ''"
        >
          <div v-if="activeSection === section" class="sidebar-sections__item-number">
            {{ `0${section}` }}
          </div>
          <div v-else class="sidebar-sections__item-circle"></div>
        </button>
      </div>
      <button
        class="sidebar-arrow sidebar-arrow--bottom"
        :disabled="activeSection >= 8"
        @click="setActiveSection(activeSection + 1)"
      ></button>
      <button class="sidebar-scrolldown" @click="setActiveSection(2)" v-if="activeSection === 1"></button>
    </div>

    <transition name="fade">
      <button class="hot-line" @click="openModal" v-if="activeSection !== 8">
        <img class="headphones" src="@/assets/img/headphones.svg" />
        Горячая линия
      </button>
    </transition>

    <transition name="fade">
      <HotLineModal v-if="isModalOpen" @close="closeModal" />
    </transition>

    <!-- 1 -->
    <section id="home-1" class="home-one">
      <video
        v-if="firstSection.preview"
        class="background"
        :src="`${API_URL}${firstSection.preview.url}`"
        playsinline
        muted
        autoplay
        loop
      ></video>
      <div class="background" :style="`opacity: ${firstSection.preview ? '0' : '1'}`">
        <img :src="`${API_URL}${firstSection.imagePreview.url}`" @load="onMainImageLoad" />
      </div>
      <div class="container">
        <img class="logo" src="@/assets/img/Logo_wh.svg" />
        <div class="title">
          <h1>{{ firstSection.title }}</h1>
        </div>
      </div>
    </section>

    <!-- 2 -->
    <section id="home-2" class="home-two">
      <video
        v-if="secondSection.preview"
        class="background"
        :src="`${API_URL}${secondSection.preview.url}`"
        playsinline
        muted
        autoplay
        loop
      ></video>
      <div class="background" :style="`opacity: ${secondSection.preview ? '0' : '1'}`">
        <img :src="`${API_URL}${secondSection.imagePreview.url}`" />
      </div>
      <div class="container">
        <img class="logo" src="@/assets/img/Logo_wh.svg" />
        <div class="title">
          <h1>{{ secondSection.title }}</h1>
        </div>
      </div>
    </section>

    <!-- 3 -->
    <section id="home-3" class="home-three">
      <video
        v-if="thirdSection.preview"
        class="background"
        :src="`${API_URL}${thirdSection.preview.url}`"
        playsinline
        muted
        autoplay
        loop
      ></video>
      <div class="background" :style="`opacity: ${thirdSection.preview ? '0' : '1'}`">
        <img :src="`${API_URL}${thirdSection.imagePreview.url}`" />
      </div>
      <div class="container">
        <img class="logo" src="@/assets/img/Logo_wh.svg" />
        <div class="title">
          <h1>{{ thirdSection.title }}</h1>
        </div>
      </div>
    </section>

    <!-- 4 -->
    <section id="home-4" class="home-four">
      <div class="background">
        <img :src="`${API_URL}${fourthSection.preview.url}`" />
      </div>
      <div class="container">
        <div class="content">
          <img class="logo" src="@/assets/img/Logo_wh.svg" />
        </div>
        <div class="numbers">
          <div class="numbers-row">
            <div class="numbers-item numbers-item--1">
              <img src="@/assets/img/stairs.svg" />
              <div class="numbers-item__text">
                <h1>{{ fourthSection.floors }}</h1>
                <p>этажа</p>
              </div>
            </div>
            <div class="numbers-item numbers-item--2">
              <img src="@/assets/img/plan.svg" />
              <div class="numbers-item__text">
                <h1>{{ fourthSection.apartments }}</h1>
                <p>квартир</p>
              </div>
            </div>
          </div>
          <div class="numbers-row">
            <div class="numbers-item numbers-item--3">
              <img src="@/assets/img/parking.svg" />
              <div class="numbers-item__text">
                <h1>{{ fourthSection.parking }}</h1>
                <p>машиномест</p>
              </div>
            </div>
            <div class="numbers-item numbers-item--4">
              <img src="@/assets/img/area.svg" />
              <div class="numbers-item__text">
                <h1>{{ fourthSection.area }}</h1>
                <p>
                  площадь нежилых <br />
                  помещений, м²
                </p>
              </div>
            </div>
          </div>
        </div>
        <router-link to="/estate">
          Подробнее
          <i class="icon-arrow-right"></i>
        </router-link>
      </div>
    </section>

    <!-- 5 -->
    <section id="home-5" class="home-five home-width-bcg">
      <div class="container">
        <div class="content">
          <img @click="setActiveSection(1)" class="logo" src="@/assets/img/Logo_bl.svg" />
          <p class="line-header">
            {{ fifthSection.title }}
            <router-link to="/projects">
              подробнее
              <div class="arrow">
                <img src="@/assets/img/Arrow_right_bl.svg" />
              </div>
            </router-link>
          </p>
        </div>
        <img class="graph" :src="`${API_URL}${fifthSection.preview.url}`" />
        <img class="graph-mobile" :src="`${API_URL}${fifthSection.mobilePreview.url}`" />
      </div>
    </section>

    <!-- 6 -->
    <section id="home-6" class="home-six home-width-bcg">
      <div class="background">
        <img :src="`${API_URL}${sixthSection.preview.url}`" />
      </div>
      <div class="container">
        <div class="content">
          <img @click="setActiveSection(1)" class="logo" src="@/assets/img/Logo_wh.svg" />
          <p class="line-header">
            {{ sixthSection.title }}
            <router-link to="/projects">
              подробнее
              <div class="arrow">
                <img src="@/assets/img/Arrow_right_wh.svg" />
              </div>
            </router-link>
          </p>
        </div>
        <div class="options-mobile">
          <div class="options-mobile-rectangle" v-for="(option, i) in sixthSection.options" :key="'mobile' + i">
            <img v-if="i == 0" src="@/assets/img/options-rectangle-start.svg" />
            <img v-else src="@/assets/img/options-rectangle.svg" />
            <span>{{ option.symbol }}</span>
          </div>
        </div>
        <div class="options">
          <div class="option" v-for="(option, i) in sixthSection.options" :key="i">
            <div class="option-rectangle">
              <img v-if="i == 0" src="@/assets/img/options-rectangle-start.svg" />
              <img v-else src="@/assets/img/options-rectangle.svg" />
              <span>{{ option.symbol }}</span>
            </div>
            <div class="option-text">
              <h1>{{ option.title }}</h1>
              <h2>{{ option.subtitle }}</h2>
              <p>{{ option.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- 7 -->
    <section id="home-7" class="home-seven home-width-bcg">
      <div class="container">
        <div class="content">
          <img @click="setActiveSection(1)" class="logo" src="@/assets/img/Logo_bl.svg" />
          <p class="line-header">{{ seventhSection.title }}</p>
        </div>
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(partner, i) in [...seventhSection.partners, ...seventhSection.partners]"
              :key="i"
            >
              <img :src="`${API_URL}${partner.preview.url}`" :title="partner.name" />
            </div>
          </div>
        </div>
      </div>
      <img class="city" src="@/assets/img/home_city.jpg" />
    </section>

    <!-- 8 -->
    <section id="home-8" class="home-eight">
      <FooterBlock />
    </section>
  </div>
</template>

<script>
import '@/assets/sass/home.sass';
import { mapState } from 'vuex';
import config from '@/config';
import HotLineModal from '@/components/HotLineModal.vue';
import $ from 'jquery';
import { Swiper } from 'swiper/swiper-bundle.min.mjs';
import FooterBlock from '@/components/FooterBlock.vue';
import marked from 'marked';

const mobileWidth = 545;

export default {
  name: 'Home',
  components: {
    HotLineModal,
    FooterBlock,
  },
  data() {
    return {
      marked,
      isLoading: true,
      isModalOpen: false,
      isScrolling: false,
      partnersSwiper: null,
      currentAdvantageId: 1,
      isSmallWindow: $(window).width() <= 1400,
      laptopActiveSection: 1,
    };
  },
  computed: {
    ...mapState({
      firstSection: (state) => state.main.mainPage.firstSection,
      secondSection: (state) => state.main.mainPage.secondSection,
      thirdSection: (state) => state.main.mainPage.thirdSection,
      fourthSection: (state) => state.main.mainPage.fourthSection,
      fifthSection: (state) => state.main.mainPage.fifthSection,
      sixthSection: (state) => state.main.mainPage.sixthSection,
      seventhSection: (state) => state.main.mainPage.seventhSection,
    }),
    API_URL() {
      return config.API_URL;
    },
    sideBarClass() {
      const reversedSections = [5, 6, 7];
      let className = '';

      if (reversedSections.includes(this.activeSection)) {
        className = 'sidebar--reversed';
      }

      if ($(window).width() <= 1400) return '';

      return className;
    },
    activeSection() {
      if (this.isSmallWindow) {
        return this.laptopActiveSection;
      }
      return Number(this.$route.query.section) || 1;
    },
  },
  methods: {
    onMainImageLoad() {
      this.isLoading = false;
    },
    loadData() {
      this.$store.dispatch('getMainPage').then(() => {
        this.$nextTick(() => {
          this.partnersSwiper = new Swiper('.swiper-container', {
            autoplay: { delay: 1, disableOnInteraction: false },
            touchReleaseOnEdges: true,
            freeMode: true,
            speed: 4000,
            loop: true,
            slidesPerView: 5,
            grid: { rows: 2 },
            breakpoints: {
              1500: {
                slidesPerView: 5,
                grid: { rows: 2 },
              },
              1200: {
                slidesPerView: 4,
                grid: { rows: 2 },
              },
              320: {
                slidesPerView: 3,
                grid: { rows: 3 },
              },
              0: {
                slidesPerView: 3,
                grid: { rows: 3 },
              },
            },
          });
        });
      });
    },
    setActiveSection(id, speed = 500) {
      const wHeight = $(window).height();
      this.$router.push({ query: { section: id } }).catch((err) => err);
      if ($(window).width() <= 1400) {
        const { top } = $(`#home-${id}`).offset();
        $('body, html').stop().animate({ scrollTop: top }, speed);
      } else {
        $('.home')
          .stop()
          .animate({ scrollTop: wHeight * this.activeSection - wHeight }, speed);
      }
      setTimeout(() => {
        this.isScrolling = false;
        this.changeSidebarClass();
      }, speed + 1);
    },
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    setCurrntAdvantage(id) {
      this.currentAdvantageId = id;
    },
    resize() {
      setTimeout(() => {
        this.setActiveSection(this.activeSection);
        this.isSmallWindow = $(window).width() <= 1400;
      }, 100);
    },
    scroll() {
      this.changeActiveSection();
      this.changeSidebarClass();
    },
    changeSidebarClass() {
      if ($(window).width() <= mobileWidth) {
        if ($(window).scrollTop() >= $(window).height() - 100) {
          $('.sidebar.sidebar-top').addClass('sidebar--reversed');
        } else {
          $('.sidebar.sidebar-top').removeClass('sidebar--reversed');
        }
        return true;
      }
      if ($(window).width() <= 1400) {
        if ($(window).scrollTop() >= $(window).height() / 3) {
          $('.sidebar.sidebar-top').addClass('sidebar--reversed');
          $('.home .sidebar').addClass('sidebar--reversed');
        } else {
          $('.sidebar.sidebar-top').removeClass('sidebar--reversed');
          $('.home .sidebar').removeClass('sidebar--reversed');
        }
        return true;
      }
      return false;
    },
    changeActiveSection() {
      if ($(window).width() <= 1400) {
        const home2 = $('#home-2').offset().top;
        const home3 = $('#home-3').offset().top;
        const home4 = $('#home-4').offset().top;
        const home5 = $('#home-5').offset().top;
        const home6 = $('#home-6').offset().top;
        const home7 = $('#home-7').offset().top;
        const home8 = $('#home-8').offset().top;
        const scrollTop = $(window).scrollTop();
        if (scrollTop < home2) {
          this.laptopActiveSection = 1;
          return;
        }
        if (scrollTop < home3) {
          this.laptopActiveSection = 2;
          return;
        }
        if (scrollTop < home4) {
          this.laptopActiveSection = 3;
          return;
        }
        if (scrollTop < home5) {
          this.laptopActiveSection = 4;
          return;
        }
        if (scrollTop < home6) {
          this.laptopActiveSection = 5;
          return;
        }
        if (scrollTop < home7) {
          this.laptopActiveSection = 6;
          return;
        }
        if (scrollTop < home8) {
          this.laptopActiveSection = 7;
        } else {
          this.laptopActiveSection = 8;
        }
      }
    },
  },
  mounted() {
    this.loadData();

    this.setActiveSection(this.activeSection, 0);

    this.changeSidebarClass();

    window.addEventListener('scroll', this.scroll);
    window.addEventListener('resize', this.resize);
    document.querySelector('.home').addEventListener('mousewheel', (e) => e.preventDefault());
    document.onwheel = (e) => {
      if ($(window).width() <= 1400) return;
      if (Math.abs(e.deltaY) < 50) return;
      if (this.isScrolling) return;
      this.isScrolling = true;
      let newSection = 0;
      if (e.deltaY > 0) {
        newSection = this.activeSection === 8 ? 8 : this.activeSection + 1;
      } else {
        newSection = this.activeSection === 1 ? 1 : this.activeSection - 1;
      }
      this.setActiveSection(newSection);
    };
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scroll);
    document.removeEventListener('resize', this.resize);
    document.onwheel = () => {};
  },
};
</script>
