<template>
  <div class="modal" @click="closeModal">
    <form class="modal-card" @submit.prevent="submit">
      <div class="modal-header">
        <img class="logo" src="@/assets/img/Logo_wh.svg" />
        <label class="modal-header__theme">
          <p>Выберите тему обращения</p>
          <select v-model="themeId" @change="themeChanged">
            <option v-for="(theme, i) in themes" :key="i" :value="theme.id">
              {{ theme.name }}
            </option>
          </select>
          <img src="@/assets/img/triangle.svg" />
        </label>
        <div class="modal-header__phone">
          <p>Мы с радостью ответим на любые Ваши вопросы по телефону:</p>
          <a :href="`tel: ${contacts.receptionNumber}`">{{ contacts.receptionNumber }}</a>
        </div>
        <button class="modal-close" @click="$emit('close')" type="button"></button>
      </div>
      <div class="modal-body" :class="themeId === 1 ? 'guarantee' : 'request'">
        <div v-if="themeId === 1" class="guarantee__content">
          <h1>Уважаемые клиенты!</h1>
          <p>
            Руководствуясь Законом РФ от 07.02.1992 N 2300-1 «О защите прав потребителей», Федеральным законом от
            30.12.2004 N 214-ФЗ «Об участии в долевом строительстве многоквартирных домов» доводим до сведения, что
            заявления по гарантийному обслуживанию объектов недвижимости необходимо оформлять в письменном в бумажном
            носителе по адресу: г. Казань, ул. Родины, д. 20Б, каб.103, либо заказным письмом посредством Почты России.
          </p>
          <h2>Часы приема граждан</h2>
          <div class="guarantee__hours">
            <span>Понедельник-пятница: 8:30 до 18:00</span>
            <span>Суббота: 09:30 до 13:00</span>
          </div>
        </div>
        <div v-if="themeId === 2">
          <input type="text" v-model="form.name" required placeholder="Введите имя" />
          <input type="tel" v-model="form.phone" required class="modal-input__phone" placeholder="Введите телефон" />
          <input type="text" v-model="form.email" required placeholder="Введите Email" />
          <textarea
            id="form-textarea"
            v-model="form.description"
            required
            placeholder="Введите сообщение"
            :style="`height: ${textareaHeight}px`"
          ></textarea>

          <div class="input-checkbox">
            <label :class="form.agree ? 'checked' : ''">
              <input type="checkbox" v-model="form.agree" />
              Настоящим я подтверждаю достоверность введенных мною сведений и даю согласие на обработку моих
              персональных данных в соответствии c
              <a :href="`${API_URL}${contacts.politics.url}`" v-if="contacts.politics" download>
                Политикой Конфиденциальности
              </a>
            </label>
            <button type="submit">
              Отправить
              <img src="@/assets/img/Arrow_top.svg" />
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Inputmask from 'inputmask';
import { mapState } from 'vuex';
import config from '@/config';

export default {
  name: 'HotLineModal',
  data() {
    return {
      themeId: 1,
      themes: [
        { id: 1, name: 'Гарантийное обслуживание' },
        { id: 2, name: 'Задать вопрос' },
      ],
      form: {
        name: '',
        phone: '',
        email: '',
        description: '',
        agree: true,
      },
    };
  },
  computed: {
    ...mapState({
      contacts: (state) => state.contacts.contacts,
    }),
    API_URL() {
      return config.API_URL;
    },
    textareaHeight() {
      const textarea = document.getElementById('form-textarea');
      const textLength = Number(this.form.description.length);
      const lineHeight = 25;
      if (!textarea) return lineHeight;
      const width = textarea.clientWidth;
      const symbolsPerWidth = Number(width) / 8.2;
      const height = Math.ceil(textLength / symbolsPerWidth) * lineHeight;
      if (height < lineHeight) return lineHeight;
      return height;
    },
  },
  methods: {
    themeChanged() {
      this.form.theme = this.themes.find((theme) => theme.id === this.themeId).name;
      if (this.themeId === 1) return;
      this.$nextTick(() => {
        const phone = document.querySelector('.modal-input__phone');
        Inputmask('+7 (999) 999-99-99').mask(phone);
      });
    },
    submit(e) {
      e.preventDefault();
      this.themeChanged();
      this.$store.dispatch('sendRequest', this.form).then(this.$emit('close'));
    },
    closeModal(event) {
      if (!event.target.classList.contains('modal')) return;
      this.$emit('close');
    },
    keydown(event) {
      if (event.keyCode !== 27) return;
      this.$emit('close');
    },
  },
  mounted() {
    document.addEventListener('keydown', this.keydown);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.keydown);
  },
};
</script>
